import React from 'react';

type PostTitleProps = {
  children: React.ReactNode;
};

const PostTitle: React.FC<PostTitleProps> = ({ children, ...otherProps }) => (
  <h1
    itemProp="name headline"
    className="my-4 text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-5.5xl"
    {...otherProps}
  >
    {children}
  </h1>
);

export default PostTitle;
