import React from 'react';
import { useTranslation } from 'react-i18next';
import { Post } from '../../utils/graphql';
import Author from './author';

type PostFooterProps = {
  post: Post;
};

const PostFooter: React.VoidFunctionComponent<PostFooterProps> = ({ post }) => {
  const { t } = useTranslation('news');
  return (
    <footer className="my-8 px-4 xs:px-4 sm:px-8 md:px-20 lg:pl-12 lg:pr-0">
      <hr className="my-4" />
      {post.authors.map((author) => (
        <Author key={author.fullName} author={author} beforeAuthor={t('writtenBy')} />
      ))}
      <hr className="my-4" />
    </footer>
  );
};

export default PostFooter;
