import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteMetadataProps, TOC } from '../../utils/graphql';
import SharingBar from '../SharingBar';

type PostTOCProps = {
  tableOfContents: TOC;
  activeSection: string;
  siteMetadata: SiteMetadataProps;
};

const PostTOC: React.VoidFunctionComponent<PostTOCProps> = ({
  tableOfContents,
  activeSection,
  siteMetadata,
}) => {
  const { t } = useTranslation('news');

  const activeSectionUrl = `#${activeSection}`;

  return (
    <div className="mb-8 mt-6 hidden text-black lg:sticky lg:top-18 lg:float-right lg:my-0 lg:block lg:w-56 xl:w-72">
      {tableOfContents.items ? (
        <nav>
          <ol className="relative ml-1 list-inside list-none">
            {tableOfContents.items.map((item) => (
              <li
                key={item.url}
                className={`mb-4 min-h-8 before:absolute before:-left-6 before:-mt-[0.375rem] before:block before:h-9 before:w-[8px] before:align-middle before:empty-content ${
                  item.url === activeSectionUrl
                    ? 'before:bg-reacteev-blue'
                    : 'before:bg-reacteev-yellow'
                }`}
              >
                <a
                  href={item.url}
                  className={`inline-block text-base ${
                    item.url === activeSectionUrl ? 'font-medium' : 'font-light'
                  }`}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ol>
        </nav>
      ) : null}
      <SharingBar
        className="-ml-8 mt-12 justify-start"
        siteUrl={siteMetadata.siteUrl}
        socialIcons={siteMetadata.social}
        t={t}
      />
    </div>
  );
};

export default PostTOC;
