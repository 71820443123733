import * as React from 'react';

function useActiveSection(): [React.RefObject<HTMLElement>, string] {
  const contentSection = React.useRef<HTMLElement>(null);
  const sections = React.useRef<string[]>([]);
  const sectionsVisibility = React.useRef<Record<string, boolean>>({});
  const [activeSection, setActiveSection] = React.useState<string>('');

  const observerCallback = React.useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      const firstChildId = entry.target.firstElementChild?.id;
      if (firstChildId) {
        sectionsVisibility.current[firstChildId] = entry.isIntersecting;
      }
      let lastVisibleSection = '';
      sections.current.forEach((section) => {
        if (sectionsVisibility.current[section]) {
          lastVisibleSection = section;
        }
      });
      setActiveSection(lastVisibleSection);
    });
  }, []);

  React.useEffect(() => {
    const observer = new IntersectionObserver(observerCallback);
    const element = contentSection.current;
    if (element) {
      element.childNodes.forEach((child) => {
        if (child.nodeName.toUpperCase() === 'SECTION') {
          const elm = child as Element;
          const firstChildId = elm.firstElementChild?.id;
          if (firstChildId) {
            sections.current.push(firstChildId);
            sectionsVisibility.current[firstChildId] = false;
          }
          observer.observe(elm);
        }
      });
    }

    return () => {
      if (element) {
        element.childNodes.forEach((child) => {
          if (child.nodeName.toUpperCase() === 'SECTION') {
            observer.unobserve(child as Element);
          }
        });
        sections.current = [];
        sectionsVisibility.current = {};
      }
    };
  }, [observerCallback]);

  return [contentSection, activeSection];
}

export default useActiveSection;
