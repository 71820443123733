import options from '../../../config/options';
import { Post } from '../../utils/graphql';
import * as Colors from '../Colors';

const defaultTheme = 'others';

const THEME_COLORS: Record<string, string> = {
  product: Colors.BLACK_ON_YELLOW,
  agility: Colors.WHITE_ON_BLUE,
  tech: Colors.WHITE_ON_BLACK,
  others: Colors.WHITE_ON_LIGHTBLUE,
};

const THEME_PSEUDO_CLASS_COLORS: Record<string, string> = {
  product: Colors.BLACK_ON_YELLOW_PSEUDO_CLASS,
  agility: Colors.WHITE_ON_BLUE_PSEUDO_CLASS,
  tech: Colors.WHITE_ON_BLACK_PSEUDO_CLASS,
  others: Colors.WHITE_ON_LIGHTBLUE_PSEUDO_CLASS,
};

const getTheme = (post: Post): string => {
  const postOption = options.post as Record<string, Record<string, unknown>>;
  return Object.keys(postOption.types).includes(post.type.toLowerCase())
    ? post.theme.toLowerCase()
    : defaultTheme;
};

export const getThemeColor = (post: Post): string => {
  return THEME_COLORS[getTheme(post)] ?? THEME_COLORS[defaultTheme];
};

export const getThemePseudoClassColor = (post: Post): string => {
  return THEME_PSEUDO_CLASS_COLORS[getTheme(post)] ?? THEME_PSEUDO_CLASS_COLORS[defaultTheme];
};
