import { graphql } from 'gatsby';
import Post from '../components/news/post';

export default Post;

export const query = graphql`
  query PostPageQuery($slug: String!, $locale: String!, $maxWidth: Int) {
    site {
      siteMetadata {
        siteUrl
        author
        social {
          name
          url
        }
      }
    }
    mdx(fields: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      frontmatter {
        title
        abstract
        duration
        type
        tags
        theme
        showTableOfContents
        authors {
          fullName
          normalizedFullName
          bio(locale: $locale)
          avatar {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 48, height: 48, placeholder: NONE)
            }
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: $maxWidth, placeholder: BLURRED)
          }
        }
        imageAlt
        socialImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: $maxWidth, backgroundColor: "transparent")
          }
        }
      }
      fields {
        date
        locale
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: $maxWidth, placeholder: BLURRED)
          }
        }
        socialImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: $maxWidth, backgroundColor: "transparent")
          }
        }
      }
      excerpt
      tableOfContents
      timeToRead
      body
    }
  }
`;
